import { gql } from '@apollo/client';

export const GQL_CREATE_XENDIT_TRANSACTION_PROXY = gql`
  mutation createXenditTransactionProxy($input: CreateXenditProxyInput) {
    createXenditTransactionProxy(input: $input) {
      xenditId
    }
  }
`;

export const GQL_GET_PAYMENT_CHANNEL = gql`
  query getPaymentChannel($id: ID!) {
    getPaymentChannel(id: $id) {
      id
      qr_string
      invoiceURL
      invoiceId
      availableBanks {
        bankCode
        bankAccountNumber
        transferAmount
        accountHolderName
      }
      availableRetailOutlets {
        retailOutletName
        paymentCode
        transferAmount
      }
      actions {
        desktopWebCheckoutUrl
        mobileWebCheckoutUrl
        qrCheckoutString
        mobileDeeplinkCheckoutUrl
      }
      expiryDate
    }
  }
`;

export const GET_PAYMENT_REQUEST = gql`
  query getPaymentRequest($paymentLinkTransactionId: ID!) {
    getPaymentRequest(paymentLinkTransactionId: $paymentLinkTransactionId) {
      id
      response
      paymentLinkTransaction {
        id
        amount
        status
        paymentMethod
        expirationDate
        customer {
          email
          mobile
        }
        paymentLink {
          name
          type
          link
          status
        }
        membershipTier {
          isSoldOut
        }
        user {
          id
          paymeLink
          isSelfDomain
          account {
            name
            xenditUserId
            logo {
              url
            }
          } 
        }
      }
    }
  }
`;

export const SIMULATE_PAYMENT = gql`
  mutation simulatePayment($paymentLinkTransactionId: ID!) {
    simulatePayment(paymentLinkTransactionId: $paymentLinkTransactionId) {
      status
      message
    }
  }
`

export const CREATE_PAYMENT_WITH_EXISTING_TRANSACTION = gql`
  mutation createPaymentWithExistingTransaction($input: CreatePaymentInput) {
    createPaymentWithExistingTransaction(input: $input) {
      status
      message
    }
  }
`;
