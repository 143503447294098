/**
 *
 * @param {string} value
 * @param {*} defaultValue
 * @returns
 */
export const parseJSON = (value, defaultValue = null) => {
  if (!value) {
    return defaultValue;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};

export const parsePaymentString = (paymentString) => {
  if (!paymentString) return null;

  const [type, channel] = paymentString.includes('/') ? paymentString.split('/') : [paymentString];

  let paymentChannel,
    channelCode = channel ? channel.toUpperCase() : '';

  switch (type.toLowerCase()) {
    case 'va':
      paymentChannel = 'VIRTUAL_ACCOUNT';
      break;
    case 'qris':
      paymentChannel = 'QR_CODE';
      channelCode = 'QR_CODE'; // No specific channel
      break;
    case 'ewallet':
      paymentChannel = 'EWALLET';
      break;
    case 'creditcard':
      paymentChannel = 'CREDIT_CARD';
      channelCode = 'CREDIT_CARD'; // No specific channel
      break;
    case 'outlet':
      paymentChannel = 'OVER_THE_COUNTER';
      break;
    default:
      paymentChannel = type.toUpperCase();
  }

  return {
    paymentChannel,
    channelCode,
  };
};
