import React from "react";
//import { useTranslation } from 'react-i18next';
// import Icon from '../icon';
import { Icon } from "@iconify/react";
import { cn } from "@/lib/utils";
const PoweredBy = ({ className, classNameBtn }: { className?: string, classNameBtn?: string }) => {
  //const { t } = useTranslation();
  //const [modal, setModal] = useState(false);
  //const toggleModal = () => setModal(!modal);

  return (
    <>
      <div
        id="powered-by-mayar"
        className={cn("text-center my-[20px]", className)}
      >
        <a
          href={"https://mayar.id/?utm_source=poweredby"}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <button
            color="link"
            className={cn(
              "hover:underline text-[#343a40] leading-[14px] text-[9px] relative inline-flex font-[600] py-[9px] px-[15px] uppercase items-center font-open-sans",
              classNameBtn
            )}
          >
            {/* <Icon name="lock" className="mr-[5px]" /> */}
            <Icon icon="octicon:lock-24" className="mr-[5px] mb-[1px] text-[15px]" />
            Powered by Mayar.ID
          </button>
        </a>
      </div>
    </>
  );
};

export default PoweredBy;
