import { gql } from '@apollo/client';

export const GQL_GET_PAYMENT_LINK_WITH_TRANSACTIONS = gql`
  query getPaymentLinkWithTransactions($link: String!) {
    getPaymentLinkWithTransactions(link: $link) {
      id
      amount
      description
      discounts
      name
      type
      status
      expiredAt
      eventStartAt
      eventUrl
      notes
      userId
      items
      target
      tax
      index
      membershipCustomerId
      order {
        id
        variant
      }
      subType
      qty
      transactions {
        id
        customer {
          name
          email
        }
      }
      user {
        name
        mobile
        kyc {
          status
        }
        account {
          name
          logo {
            url
          }
        }
      }
      customer {
        id
        email
        name
        mobile
      }
      coverImage {
        url
      }
      form {
        fieldObject
      }
    }
  }
`;

export const GQL_CREATE_PAYMENTTRANSACTION_BY_USERID = gql`
  mutation createPaymentTransactionByUserId($input: PaymentLinkTransactionInput!) {
    createPaymentTransactionByUserId(input: $input) {
      id
      status
      paymentLinkId
      customerId
      xenditId
    }
  }
`;

export const GQL_CREATE_PAYMENTTRANSACTION_SUPPORT = gql`
  mutation createPaymentTransactionSupport($input: PaymentLinkTransactionInput!) {
    createPaymentTransactionSupport(input: $input) {
      id
      status
      paymentLinkId
      customerId
      xenditId
    }
  }
`;

export const GQL_CREATE_PAYMENTTRANSACTION_FUNDRAISING = gql`
  mutation createPaymentTransactionFundraising($input: PaymentLinkTransactionInput!) {
    createPaymentTransactionFundraising(input: $input) {
      id
      status
      paymentLinkId
      xenditId
    }
  }
`;

export const GQL_EDIT_PAYMENTTRANSACTION = gql`
  mutation editPaymentTransactionPublic($input: PaymentLinkTransactionEditInput!) {
    editPaymentTransactionPublic(input: $input) {
      id
      isAllModulesViewed
    }
  }
`;

export const GQL_SAVE_COURSEMODULE_NOTES_PAYMENTTRANSACTION = gql`
  mutation saveCustomerCourseModulesNotes($input: PaymentLinkTransactionEditInput!) {
    saveCustomerCourseModulesNotes(input: $input) {
      id
      courseModulesNotes
      errorMessage
    }
  }
`;

export const GQL_GET_PAYMENTLINKTRANSACTION_BY_ID_PUBLIC = gql`
  query getPaymentLinkTransactionByIdPublic($id: ID!) {
    getPaymentLinkTransactionByIdPublic(id: $id) {
      id
      amount
      createdAt
      givenCustomerId
      customerId
      paymentLinkDescription
      paymentLinkId
      paymentLinkName
      status
      updatedAt
      expiredTime
      portalUrl
      token
      method
      expirationDate
      isContentAvailable
      ticketUrl
      membershipTierRedirectUrl
      licenseCode {
        id
        licenseCode
      }
      eventHistory {
        barcode
        ticket {
          id
          amount
          description
          name
        }
      }
      membershipTier {
        notes
        isSoldOut
      }
      customer {
        email
        name
        mobile
      }
      couponUsage {
        coupons {
          code
          discount {
            value
            discountType
          }
        }
      }
      items {
        id
        amount
        paymentLink {
          id
          amount
          name
          type
          link
          coverImage {
            url
          }
        }
      }
      paymentLink {
        id
        type
        redirectUrl
        link
        status
        eventStartAt
        eventEndAt
        eventUrl
        notes
        limit
        amount
        description
        name
        terms
        membershipInfo {
          type
        }
        event {
          isOnlineEvent
          eventAddress
        }
        user {
          paymeLink
          name
          userAnalytics {
            facebookPixelId
            gtmId
          }
          account {
            name
            logo {
              url
            }
            isVerified
          }
          payChannelConfig {
            config
            isAdminFeeBorneByCustomer
            isAkulakuApproved
            isCreditCardApproved
          }
        }
        customer {
          id
          email
          name
        }
        coverImage {
          url
        }
      }
      conversionSetting {
        isPixelServerSide
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
    }
  }
`;

export const GQL_GET_PAYMENTLINKTRANSACTION_BY_ID_PUBLIC_STATUS = gql`
  query getPaymentLinkTransactionByIdPublic($id: ID!) {
    getPaymentLinkTransactionByIdPublic(id: $id) {
      id
      amount
      createdAt
      customerId
      status
      portalUrl
    }
  }
`;

export const GQL_GET_PAYMENT_RESULT = gql`
  query getPaymentResultProxy($id: ID!) {
    getPaymentResultProxy(id: $id) {
      id
      method
      date
      amount
      logo
      redirectUrl
      merchantName
      paymentLinkId
      paymentLinkType
      token
      ticketUrl
      givenCustomerId
      membershipTierRedirectUrl
      licenseCode {
        id
        licenseCode
      }
      customer {
        email
        mobile
        name
      }
      eventHistory {
        barcode
        ticket {
          id
          amount
          description
          name
        }
      }
      paymentLink {
        id
        eventStartAt
        eventEndAt
        eventUrl
        notes
        name
        description
        redirectUrl
        type
        terms
        membershipInfo {
          type
        }
        event {
          isOnlineEvent
          eventAddress
        }
        user {
          userAnalytics {
            facebookPixelId
            gtmId
          }
        }
      }
      isContentAvailable
      portalUrl
      conversionSetting {
        isPixelServerSide
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
    }
  }
`;
