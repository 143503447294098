import { gql } from '@apollo/client';

export const GQL_GET_COURSE_PAGE_PUBLIC = gql`
  query getCoursePageByUsername(
    $username: String!
    $page: Int
    $pageSize: Int
    $id: ID
    $link: String
    $status: String
  ) {
    getCoursePageByUsername(
      username: $username
      page: $page
      pageSize: $pageSize
      id: $id
      link: $link
      status: $status
    ) {
      errorMessage
      items {
        user {
          bunnyLibraryId
          account {
            name
            email
            mobile
            profile
            logo {
              url
            }
          }
        }
        id
        amount
        crossoutPrice
        status
        startAt
        createdAt
        description
        link
        name
        redirectUrl
        updatedAt
        type
        ackeeAnalytics {
          analyticDomainId
          analyticEventId
          eventType
        }
        coverImage {
          url
          width
          height
        }
        multipleImage {
          url
          width
          height
        }
        courseSections {
          description
          id
          name
          position
          link
          courseModules {
            id
            name
            type
            position
            link
            description
            isPreview
            isContentNotProtected
            isVisible
            coachingUrl
            embedUrl
            quiz
            content {
              files {
                id
                filename
                size
                mimetype
              }
              file {
                id
                filename
                size
                mimetype
                bunnyVideoId
              }
              id
            }
          }
        }
        checkoutSettings {
          buttonName
        }
      }
      conversionSettings {
        trackingId
        type
        conversionSettingEvents {
          name
          page
        }
      }
      total
    }
  }
`;

export const GQL_GET_QUIZZES_AVERAGE_GRADE = gql`
  query getQuizzesAverageGrade($paymentLinkTransactionId: ID!, $paymentLinkId: ID!, $customerId: ID!) {
    getQuizzesAverageGrade(
      paymentLinkTransactionId: $paymentLinkTransactionId
      paymentLinkId: $paymentLinkId
      customerId: $customerId
    ) {
      averageQuizzesGrade
    }
  }
`;
