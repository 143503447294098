import slugLimax from "limax";

export const formatRp = (value) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  if (!value) {
    return formatter.format(0).replace(/,00/, "");
  }

  return formatter.format(value).replace(/,00/, "");
};

export const formatHistoryOrder = (amount) => {
  let history = amount.toString();

  if (amount === 0) return "Belum Terjual";
  if (history.length > 3) {
    const val = amount / 1000;
    history = Number(Math.round(val + "e" + 1) + "e-" + 1) + " rb";
  }

  return `Terjual ${history}`;
};

export const truncateText = (source, maxLength) => {
  return source?.length > maxLength
    ? `${source.substring(0, maxLength).trim()}...`
    : source;
};

export const sortByLatestDateTime = (items) =>
  items.slice().sort((a, b) => b.createdAt - a.createdAt);
export const sortByLongestDateTime = (items) =>
  items.slice().sort((a, b) => a.createdAt - b.createdAt);

export const delimiter = (value) => {
  const formatter = new Intl.NumberFormat("id-ID");
  return formatter.format(value).replace(/,00/, "");
};

export const camelCaseStringBySeparator = (string = "", separator = ",") => {
  return string
    ?.split(separator)
    .map((str) => `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`)
    .join(separator);
};

export const slug = (text = "") => slugLimax(text.normalize("NFKD"));

export const formatQtyPerSeven = (qty, type) => {
  // use on db to dashboard for qurban quantity
  if (type === "SAPI") {
    const qtyPerSeven = qty / 7;
    return qtyPerSeven;
  }

  return qty;
};

export const formatQtySevenPerOne = (qty, type) => {
  // use on dashboard to db for qurban quantity
  if (type === "SAPI") {
    const qtySevenPerOne = qty * 7;
    return qtySevenPerOne;
  }

  return qty;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const convertTypeToReadable = (type) => {
  const typeMap = {
    digital_product: "Produk Digital",
    physical_product: "Produk Fisik",
    course: "Kelas online",
    cohort_based: "Kelas cohot based",
    generic_link: "Link pembayaran",
    payment_request: "Penagihan",
    fundraising: "Penggalangan dana",
    saas: "Saas / License Code",
  };

  if (typeMap[type]) return capitalizeFirstLetter(typeMap[type]);

  return capitalizeFirstLetter(type);
};

export const getCurrentDimension = () => ({
  width: typeof window !== "undefined" && window.innerWidth,
  height: typeof window !== "undefined" && window.innerHeight,
});

export const isJSON = (data) => {
  let ret = true;

  if (!data) {
    return false;
  }

  try {
    JSON.parse(data);
  } catch (e) {
    ret = false;
  }

  return ret;
};

/* Function to flatlined an array to a string separated by (,) and (&) */
export const flatlinedArrToString = (arr) => {
  let str = "";

  arr.forEach((item, index) => {
    if (index === arr.length - 1) {
      str += item;
    } else if (index !== arr.length - 2) {
      str += `${item}, `;
    } else {
      str += `${item} & `;
    }
  });

  return str;
};

export const getUrlParamsAsArray = (url) => {
  const params = new URL(url).searchParams;
  const paramArray = [];

  for (const [key, value] of params.entries()) {
    paramArray.push(`${key}=${value}`);
  }

  return paramArray;
};

export const getSearchParamsString = (searchParams) => {
  if (!searchParams.length) return "";
  return (
    "?" +
    searchParams
      .map((param) => `${Object.keys(param)}=${Object.values(param)}`)
      .join("&")
  );
};

export const convertToInternationalFormat = (phoneNumber) => {
  // Remove any non-numeric characters except +
  const numericPhone = phoneNumber.replace(/[^\d+]/g, '');

  if (numericPhone.startsWith('0')) {
    return '+62' + numericPhone.slice(1);
  }

  // If the phone number already starts with a country code, return it as is
  return numericPhone;
}

export const convertKeysToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    // Process each element in the array recursively
    return obj.map(item => convertKeysToSnakeCase(item));
  } else if (obj && typeof obj === 'object') {
    // Process each key-value pair in the object recursively
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const snakeKey = camelToSnake(key); 
      acc[snakeKey] = convertKeysToSnakeCase(value); // Recursively process the value
      return acc;
    }, {});
  }
  return obj; // Return primitive values as-is
};
